export const nodeValueParams = {
    y: 30,
    opacity: 0
}
export const nodeValueAnimate = {
    y: 0,
    opacity: 100
}
export const nodeValueExit = {
}
export const initialAnimationComparison = {
    x: 100,
    opacity: 0
}
export const finalAnimationComparison = {
    x: 0,
    opacity: 100
}
export const exitAnimationComparison = {
    x: -100,
    opacity: 0
}